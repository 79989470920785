import {Button, Stack, TableCell, TableRow,} from "@mui/material";
import React, {useState} from "react";
import IntentDialog from "./IntentDialog";
import PayIntentDialog from "./PayIntentDialog";
import PaidTransactionsDialog from "./PaidTransactionsDialog";
import {Intent, Pocket, Transaction} from "../logic/types";
import {buildPocketInfo} from "../logic/helpers";
import Typography from "@mui/material/Typography";

export type Props = {
  onChangedIntent: () => void
  onDeletedIntent: () => void
  onPaidMoney: () => void
  onChangedPaidTransaction: () => void
  onDeletedPaidTransaction: () => void
  intent: Intent
  index: number
  transactions: Array<Transaction>
  selectedMonth: Date
  pockets: Array<Pocket>
}

export default function IntentListElement(props: Props) {
  const [showIntentDialog, setShowIntentDialog] = useState(false)
  const [showPayIntentDialog, setShowPayIntentDialog] = useState(false)
  const [showPaidTransactionsDialog, setShowPaidTransactionsDialog] = useState(false)
  const handleClosePayIntentDialog = () => setShowPayIntentDialog(false)
  const handleShowPayIntentDialog = () => setShowPayIntentDialog(true)
  const handleClosePaidTransactionsDialog = () => setShowPaidTransactionsDialog(false)

  // @ts-ignore
  function handleStatusClick(e) {
    e.stopPropagation();
  }

  // @ts-ignore
  function handlePaidClick(e) {
    e.stopPropagation();
    console.log(props.transactions)
    setShowPaidTransactionsDialog(true)
  }

  function transactionsSum(): number {
    let sum: number = 0
    for (let i = 0; i < props.transactions.length; i++) {
      sum = sum + props.transactions[i].amount
    }
    return sum
  }

  function calculateAmountLeft(): number {
    return props.intent.amount - transactionsSum()
  }

  return (
    <>
      <TableRow onClick={() => setShowIntentDialog(true)}>
        <TableCell align="center">
          <Stack direction="column" justifyContent="center" alignItems="center">
            {props.intent.description}
            <Typography variant="caption" style={{marginTop: '-5px', opacity: 0.5}}>
              {buildPocketInfo(props.pockets, props.intent.fromPocketID, props.intent.toPocketID)}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">{props.intent.amount}</TableCell>
        <TableCell align="center" onClick={handleStatusClick}>
          {
            transactionsSum() >= props.intent.amount
              ? 'Оплачено'
              : <Button
                variant="contained"
                onClick={handleShowPayIntentDialog}
                // style={{ minWidth: '80px' }}
              >
                {calculateAmountLeft()}
              </Button>
          }
        </TableCell>
        <TableCell align="center" onClick={handlePaidClick}>
          {props.transactions.length === 0 ? '' : transactionsSum()}
        </TableCell>
      </TableRow>

      <PayIntentDialog
        show={showPayIntentDialog}
        onClose={handleClosePayIntentDialog}
        onPaidMoney={props.onPaidMoney}
        intent={props.intent}
        selectedMonth={props.selectedMonth}
        amountLeft={calculateAmountLeft()}
      />

      <IntentDialog
        show={showIntentDialog}
        onClose={() => setShowIntentDialog(false)}
        onChangedIntent={() => props.onChangedIntent()}
        onDeletedIntent={() => props.onDeletedIntent()}
        intent={props.intent}
        selectedMonth={props.selectedMonth}
        pockets={props.pockets}
      />

      <PaidTransactionsDialog
        show={showPaidTransactionsDialog}
        onClose={handleClosePaidTransactionsDialog}
        transactions={props.transactions}
        onChangedTransaction={props.onChangedPaidTransaction}
        onDeletedTransaction={props.onDeletedPaidTransaction}
        pockets={props.pockets}
      />
    </>
  );
}
