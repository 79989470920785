import './App.css';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import {ThemeProvider, Typography} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React, {useEffect, useState} from "react";
import DailyTransactionsPage from "./Pages/DailyTransactionsPage";
import IncomePage from "./Pages/IncomePage";
import IntentsPage from "./Pages/IntentsPage";
import LoginPage from "./Pages/LoginPage";
import {Route, Routes, useNavigate} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import NotFoundPage from "./Pages/NotFoundPage";
import {AuthContext, AuthContextInterface} from "./logic/AuthContext";
import PocketsPage from "./Pages/PocketsPage";
import LogoutButton from "./Components/LogoutButton";
import {loadIsLoggedIn, removeAccessToken} from "./logic/helpers";
import {createTheme} from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ExamplePage from "./Pages/ExamplePage";


function App() {

  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const [isAuthenticated, userHasAuthenticated] = useState(false);

  const authContext: AuthContextInterface = {
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: userHasAuthenticated,
  };

  const theme = createTheme({

    palette: {
      primary: {
        main: '#6750A4',
      },

    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const isLoggedIn = loadIsLoggedIn();
      userHasAuthenticated(isLoggedIn)
    } catch (e) {
      alert(e);
    }

    setIsAuthenticating(false);
  }, []);

  async function handleLogout() {

    // Удаляем значение логина из хранища браузера
    removeAccessToken()

    // Меняем состояние логина
    userHasAuthenticated(false);

    navigate('/')
  }

  if (isAuthenticating) {
    return null
  } else {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <AppBar
            position="sticky"
            style={{backgroundColor: "#6750A4"}}>
            <Toolbar>

              {
                isAuthenticated && <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    title='Дом'
                    style={{flexDirection: 'column'}}
                    // sx={{ mr: 2, display: { sm: 'none' } }}
                    onClick={() => navigate('/')}
                  >
                    <HomeOutlinedIcon sx={{fontSize: 40}}/>
                  </IconButton>

                  <IconButton
                    edge="start"
                    color="inherit"
                    style={{flexDirection: 'column', marginLeft: '10px'}}
                    title='Запланированные траты'
                    onClick={() => navigate('/intent')}
                  >
                    <AddBusinessIcon/>
                    <Typography variant="caption" display="block">план</Typography>
                  </IconButton>

                  <IconButton
                    edge="start"
                    color="inherit"
                    title='Доходы'
                    style={{flexDirection: 'column', marginLeft: '10px'}}
                    onClick={() => navigate('/income')}
                  >
                    <EuroOutlinedIcon/>
                    <Typography variant="caption" display="block">доход</Typography>

                  </IconButton>

                  <IconButton
                    edge="start"
                    color="inherit"
                    title='Доходы'
                    style={{flexDirection: 'column', marginLeft: '10px'}}
                    onClick={() => navigate('/transactions')}>
                    <CalculateOutlinedIcon/>
                    <Typography variant="caption" display="block">детали</Typography>
                  </IconButton>

                  <IconButton
                    edge="start"
                    color="inherit"
                    title='Покеты'
                    style={{flexDirection: 'column', marginLeft: '10px'}}
                    onClick={() => navigate('/pockets')}>
                    <AccountBalanceWalletOutlinedIcon/>
                    <Typography variant="caption" display="block">покеты</Typography>
                  </IconButton>
                </>
              }

              <div style={{flex: 1}}/>

              {
                isAuthenticated
                  ? <LogoutButton
                    onLogoutButton={handleLogout}
                  />
                  :
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => navigate('/login')}
                  >
                    <LoginOutlinedIcon/>
                  </IconButton>
              }
            </Toolbar>
          </AppBar>
        </ThemeProvider>


        <AuthContext.Provider value={authContext}>
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="pockets" element={<PocketsPage/>}/>
            <Route path="login" element={<LoginPage/>}/>
            <Route path="intent" element={<IntentsPage/>}/>
            <Route path="income" element={<IncomePage/>}/>
            <Route path="transactions" element={<DailyTransactionsPage/>}/>
            <Route path="example" element={<ExamplePage/>}/>


            <Route path="*" element={<NotFoundPage/>}/>
          </Routes>
        </AuthContext.Provider>
      </div>
    )
  }
}

export default App;
