import { loadAccessToken } from "./helpers";
import {
  IntentRequest,
  LoginRequest,
  LoginResponse,
  MonthDetails, PocketDetails, PocketRequest,
  TodayDetails, TransactionRequest
} from "./types";

const apiBaseURL = 'https://money-back-ec2.katze.link/api'
const apiTransactionsURL = apiBaseURL + '/transactions'
const apiIntentsURL = apiBaseURL + '/intents'
const apiPocketsURL = apiBaseURL + '/pockets'
const apiMonthDetailsURL = apiBaseURL + '/month-details'
const apiTodayDetailsURL = apiBaseURL + '/today-details'

export async function apiLogin(request: LoginRequest): Promise<LoginResponse | null> {
  // await new Promise(r => setTimeout(r, 2000))
  try {
    const serverResponse = await fetch(apiBaseURL + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(request)
    })

    if (serverResponse.ok) {
      const response = await serverResponse.json() as LoginResponse
      console.log(`login ok: access allowed`)
      return response
    } else if (serverResponse.status === 401) {
      alert('емейл или пароль неверны')
    } else {
      alert(`login Ошибка HTTP: ${serverResponse.status}: ${serverResponse.statusText}`)
    }
  } catch (err) {
    alert(`login error Ошибка: ${err}`)
  }

  return null
}

export async function apiFetchMonthDetails(when: Date): Promise<MonthDetails | null> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return null
    }

    const url = apiMonthDetailsURL + `?date=${when.toISOString()}`
    const serverResponse = await fetch(url, {
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    if (serverResponse.ok) {
      return await serverResponse.json() as MonthDetails;
    } else {
      alert("fetchMonthDetails Ошибка HTTP: " + serverResponse.status);
    }
  } catch (err) {
    alert(`fetchMonthDetails Ошибка: ${err}`)
  }

  return null
}

export async function apiFetchTodayDetails(): Promise<TodayDetails | null> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return null
    }

    const serverResponse = await fetch(apiTodayDetailsURL, {
      headers: { 'Authorization': `Bearer ${accessToken}` },
    })
    if (serverResponse.ok) {
      return await serverResponse.json() as TodayDetails
    } else {
      alert("fetchTodayDetails Ошибка HTTP: " + serverResponse.status);
    }
  } catch (err) {
    alert(`fetchTodayDetails Ошибка: ${err}`)
  }

  return null
}

export async function apiAddTransaction(request: TransactionRequest): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const serverResponse = await fetch(apiTransactionsURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(request)
    })

    if (serverResponse.ok) {
      console.log('Успешно добавили транзакции')
      return true
    } else {
      alert(`Ошибка HTTP при добавлении транзакции: ${serverResponse.status}`)
    }
  } catch (err) {
    alert(`Ошибка при добавлении транзакции: ${err}`)
  }

  return false
}

export async function apiChangeTransaction(transactionID: number, request: TransactionRequest): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const url = apiTransactionsURL + '/' + transactionID
    const serverResponse = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(request)
    })

    if (serverResponse.ok) {
      console.log('Успешно изменили транзакции')
      return true
    } else {
      alert(`Ошибка HTTP при изменении транзакции: ${serverResponse.status}`)
    }
  } catch (err) {
    alert(`Ошибка при изменении транзакции: ${err}`)
  }

  return false
}

export async function apiDeleteTransaction(transactionID: number): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const url = apiTransactionsURL + '\\' + transactionID
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    if (response.ok) {
      console.log('Успешно удалили транзакции')
      return true
    } else {
      alert("Ошибка HTTP при удалении транзакции: " + response.status);
    }
  } catch (err) {
    alert(`Ошибка при удалении транзакции: ${err}`)
  }

  return false
}

export async function apiAddIntent(request: IntentRequest): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const serverResponse = await fetch(apiIntentsURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(request)
    })

    if (serverResponse.ok) {
      console.log('Успешно добавили запланированную деньгу')
      return true
    } else {
      alert(`Ошибка HTTP при добавлении запланированной деньги: ${serverResponse.status}`)
    }
  } catch (err) {
    alert(`Ошибка при добавлении деньги: ${err}`)
  }

  return false
}

export async function apiChangeIntent(intentID: number, request: IntentRequest): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const url = apiIntentsURL + '/' + intentID
    const serverResponse = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(request)
    })

    if (serverResponse.ok) {
      console.log('Успешно изменили деньгу')
      return true
    } else {
      alert(`changeIntent Ошибка HTTP: ${serverResponse.status}`)
    }
  } catch (err) {
    alert(`changeIntent Ошибка: ${err}`)
  }

  return false
}

export async function apiDeleteIntent(intentID: number, date: Date): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const url = apiIntentsURL + '/' + intentID + `?month=${getMonthParam(date)}`
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    if (response.ok) {
      return true
    } else {
      alert("deleteIntent Ошибка HTTP: " + response.status);
    }
  } catch (err) {
    alert(`deleteIntent Ошибка: ${err}`)
  }

  return false
}

export async function apiIgnoreIntent(intentID: number, date: Date): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const url = apiIntentsURL + '/' + intentID + `/ignore?month=${getMonthParam(date)}`
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    if (response.ok) {
      return true
    } else {
      alert("ignoreIntent Ошибка HTTP: " + response.status);
    }
  } catch (err) {
    alert(`ignoreIntent Ошибка: ${err}`)
  }

  return false
}

export async function apiAddPocket(request: PocketRequest): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const serverResponse = await fetch(apiPocketsURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(request)
    })

    if (serverResponse.ok) {
      console.log('Успешно добавили кошелек')
      return true
    } else {
      alert(`Ошибка HTTP при добавлении кошелька: ${serverResponse.status}`)
    }
  } catch (err) {
    alert(`Ошибка при добавлении кошелька: ${err}`)
  }

  return false
}

export async function apiChangePocket(pocketID: number, request: PocketRequest): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const url = apiPocketsURL + '/' + pocketID
    const serverResponse = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(request)
    })

    if (serverResponse.ok) {
      console.log('Успешно изменили кошелек')
      return true
    } else {
      alert(`changePocket Ошибка HTTP: ${serverResponse.status}`)
    }
  } catch (err) {
    alert(`changePocket Ошибка: ${err}`)
  }

  return false
}

export async function apiDeletePocket(pocketID: number): Promise<boolean> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return false
    }

    const url = apiPocketsURL + '/' + pocketID
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    if (response.ok) {
      console.log('Успешно удалили кошелек')
      return true
    } else {
      alert("deletePocket Ошибка HTTP: " + response.status);
    }
  } catch (err) {
    alert(`deletePocket Ошибка: ${err}`)
  }

  return false
}

export async function apiFetchPocketDetails(pocketID: number): Promise<PocketDetails | null> {
  try {
    const accessToken = loadAccessToken()
    if (!accessToken) {
      alert("Отсутсвует accessToken")
      return null
    }

    const url = apiPocketsURL + '/' + pocketID
    const serverResponse = await fetch(url, {
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    if (serverResponse.ok) {
      return await serverResponse.json() as PocketDetails;
    } else {
      alert("apiFetchPocketDetails Ошибка HTTP: " + serverResponse.status);
    }
  } catch (err) {
    alert(`apiFetchPocketDetails Ошибка: ${err}`)
  }

  return null
}

function getMonthParam(date: Date): String {
  return `${date.getFullYear()}-${date.getMonth() + 1}`
}
