import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  Slide,
  Stack,
  TextField
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent, useState } from "react";
import { apiAddTransaction } from "../logic/requests";
import { Intent, TransactionRequest } from "../logic/types";
import { LoadingButton } from "@mui/lab";

type Props = {
  show: boolean
  onClose: () => void
  onPaidMoney: () => void
  intent: Intent
  selectedMonth: Date
  amountLeft: number
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} children={props.children} />;
});


export default function PayIntentDialog(props: Props) {
  const [description, setDescription] = useState("");
  const [payAmount, setPayAmount] = useState(props.amountLeft)

  // @ts-ignore
  async function handlePayButton(e) {
    console.log("handlePayButton")
    e.stopPropagation()

    const request: TransactionRequest = {
      amount: payAmount,
      color: props.intent.color,
      paidAt: props.selectedMonth.toISOString(),
      description: description !== '' ? description : props.intent.description,
      intentID: props.intent.id,
      fromPocketID: props.intent.fromPocketID,
      toPocketID: props.intent.toPocketID
    }

    const added = await apiAddTransaction(request)
    if (added) {
      props.onPaidMoney()
      props.onClose()
    }
  }

  function handleChangePayAmount(event: ChangeEvent<HTMLInputElement>) {
    setPayAmount(parseInt(event.target.value))
  }

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    setDescription(event.target.value)
  }

  // function validateForm(): boolean {
  //   return payAmount > 0;
  // }

  return (
    <Dialog
      fullScreen
      open={props.show}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{backgroundColor: "#6750A4"}}
        sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          </Typography>
          <LoadingButton color="inherit"
                  onClick={handlePayButton}>
            Оплатить
          </LoadingButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Stack component="form" spacing={2}>
          <TextField disabled
                     label="Сумма"
                     type="number"
                     value={props.amountLeft}
          >Запланировано
          </TextField>
          <TextField autoFocus
                     type="number"
                     onChange={handleChangePayAmount}
                     value={payAmount}
          >Потрачено
          </TextField>

          <TextField
            label="Описание"
            type="text"
            onChange={handleChangeDescription}
            value={description}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
