import {TableCell, TableRow} from "@mui/material";
import React, {useState} from "react";
import DailyTransactionsDialog from "./DailyTransactionsDialog";
import {formatAmount} from "../logic/helpers";
import {Pocket, Transaction} from "../logic/types";

type Props = {
  transactions: Array<Transaction>
  onChangedTransaction: () => void
  onDeletedTransaction: () => void
  onAddedTransactionButton: () => void
  dayNumber: number
  dayBudget: number
  selectedMonth: Date
  transactionsSumOfDay: number
  pockets: Array<Pocket>
}

export default function DailyTransactionsListElement(props: Props) {
  const [showDailyTransactionsDialog, setShowDailyTransactionsDialog] = useState(false)
  const handleCloseDailyTransactionsDialog = () => setShowDailyTransactionsDialog(false)

  const isCurrentMonth = props.selectedMonth.getMonth() === new Date().getMonth() && props.selectedMonth.getFullYear() === new Date().getFullYear()
  const now = props.selectedMonth
  const date = new Date(now.getFullYear(), now.getMonth(), props.dayNumber)
  const isPast = isCurrentMonth && date.getDate() < now.getDate()
  const isToday = isCurrentMonth && date.getDate() === now.getDate()

  return (
    <>
      <TableRow style={{
        ...(isPast ? {opacity: 0.5} : {}),
        ...(isToday ? {backgroundColor: "rgba(148,89,187,0.61)"} : {})
      }}>
        <TableCell align="left">{`${props.dayNumber} ${date.toLocaleDateString('ru-RU', { weekday: 'long' })}`}</TableCell>
        <TableCell align="center">{formatAmount(props.dayBudget)}</TableCell>
        <TableCell align="center" onClick={() => setShowDailyTransactionsDialog(true)}>
          {props.transactionsSumOfDay} </TableCell>
      </TableRow>

      <DailyTransactionsDialog
        show={showDailyTransactionsDialog}
        onClose={handleCloseDailyTransactionsDialog}
        onChangedTransaction={props.onChangedTransaction}
        onDeletedTransaction={props.onDeletedTransaction}
        transactions={props.transactions}
        onAddedTransactionButton={props.onAddedTransactionButton}
        date={date}
        pockets={props.pockets}
      />
    </>
  )
}
