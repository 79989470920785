import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider} from "@mui/material";
import React, {useState} from "react";
import {useAuthContext} from "../logic/AuthContext";
import DailyTransactionsListElement from "../Components/DailyTransactionsListElement";
import {Transaction} from "../logic/types";
import LoginSuggestion from "../Components/LoginSuggestion";
import useGlobalSelectedMonth from "../hooks/useGlobalSelectedMonth";
import useMonthDetails from "../hooks/useMonthDetails";
import MonthSelector from "../Components/MonthSelector";
import {geldTheme} from "../style/geldTheme";
import {transactionMainAmount} from "../logic/transaction";

export default function DailyTransactionsPage(): JSX.Element {
  const {isAuthenticated} = useAuthContext()

  const [selectedMonth, setSelectedMonth] = useGlobalSelectedMonth()
  const [monthDetails, fetchMonthDetails] = useMonthDetails({selectedMonth})
  const [fetching, setFetching] = useState(false)

  const date = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0)

  async function onChangeSelectedMonth(date: Date) {
    setFetching(true)
    setSelectedMonth(date)
    await fetchMonthDetails(date)
    setFetching(false)
  }

  function renderDetails() {
    if (fetching) {
      return <div>Загрузка...</div>
    }

    function sumDailyTransactions(): number {
      return monthDetails.transactions
        .filter((transaction) => !transaction.intentID)
        .reduce((sum, transaction) => sum + transaction.amount, 0)
    }

    function dailyTransactionsListElements(): Array<JSX.Element> {
      // const date = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0)
      const numberOfDaysInMonth = date.getDate()

      const dailyTransactions: Array<Array<Transaction>> = []
      for (let dayIndex = 0; dayIndex < numberOfDaysInMonth; dayIndex++) {
        dailyTransactions.push([])
      }

      for (let transactionIndex = 0; transactionIndex < monthDetails.transactions.length; transactionIndex++) {
        let transaction = monthDetails.transactions[transactionIndex]

        if (!transaction.intentID) {
          const dayIndex = new Date(transaction.paidAt).getDate() - 1
          dailyTransactions[dayIndex].push(transaction)
        }
      }

      const listElements = []
      let transactionsSumOfMonth = 0
      for (let dayIndex = 0; dayIndex < numberOfDaysInMonth; dayIndex++) {

        let transactionsSumOfDay = 0
        for (let transactionIndex = 0; transactionIndex < dailyTransactions[dayIndex].length; transactionIndex++) {
          transactionsSumOfDay += transactionMainAmount(dailyTransactions[dayIndex][transactionIndex]) ?? 0
        }
        transactionsSumOfMonth += transactionsSumOfDay

        const dayNumber = dayIndex + 1
        const dayBudget = dayNumber * monthDetails.everydayBaseBudget + transactionsSumOfMonth

        listElements.push(<DailyTransactionsListElement
            dayBudget={dayBudget}
            dayNumber={dayNumber}
            transactions={dailyTransactions[dayIndex]}
            onChangedTransaction={fetchMonthDetails}
            onDeletedTransaction={fetchMonthDetails}
            onAddedTransactionButton={fetchMonthDetails}
            key={dayIndex}
            selectedMonth={selectedMonth}
            transactionsSumOfDay={transactionsSumOfDay}
            pockets={monthDetails.pockets}
          />
        )
      }
      return listElements
    }

    return (
      <>
        <ThemeProvider theme={geldTheme}>
          <MonthSelector
            style={{float: 'right', padding: '8px'}}
            selectedMonth={selectedMonth}
            onChangeSelectedMonth={onChangeSelectedMonth}
          />

          <TableContainer component={Paper}>
            <Table
              sx={{minWidth: devicePixelRatio, maxWidth: 400, margin: 'auto'}}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Дата</TableCell>
                  <TableCell align="center">Бюджет на день</TableCell>
                  <TableCell align="center">Итого</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dailyTransactionsListElements().sort(() => 0)}
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell align="left"/>
                  <TableCell align="center">ИТОГО</TableCell>
                  <TableCell align="center">{sumDailyTransactions()}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </>
    )
  }

  return (
    <div>
      {isAuthenticated ? renderDetails() : LoginSuggestion()}
    </div>
  )
}