import {Intent, MoneyColor, MonthDetails, Transaction} from "./types";

export function getIntentPaidTransactions(monthDetails: MonthDetails, intent: Intent): Array<Transaction> {
  const transactions: Array<Transaction> = []
  for (let i = 0; i < monthDetails.transactions.length; i++) {
    if (intent.id === monthDetails.transactions[i].intentID)
      transactions.push(monthDetails.transactions[i])
  }
  return transactions
}

export function transactionMainAmount(t: Transaction): number | undefined {
  const color = transactionMainColor(t)

  if (color == MoneyColor.Income) {
    return t.amount
  }

  if (color == MoneyColor.Expense) {
    return -t.amount
  }

  return undefined
}

export function transactionMainColor(t: Transaction): MoneyColor | undefined {
  if (t.fromPocketID != null && t.toPocketID != null) {
    return undefined
  }

  if (t.color) {
    return t.color
  }

  if (t.fromPocketID != null) {
    return MoneyColor.Income
  }
  if (t.toPocketID != null) {
    return MoneyColor.Expense
  }

  return undefined // Transaction has no color and no pocket
}
