import { createContext, useContext } from "react";

export interface AuthContextInterface {
  isAuthenticated: boolean,
  setIsAuthenticated: (value: boolean) => void,
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
});

// Docs: https://ru.reactjs.org/docs/context.html
export function useAuthContext(): AuthContextInterface {
  return useContext(AuthContext);
}
