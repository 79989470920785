import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, Slide } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import React from "react";
import { LoadingButton } from "@mui/lab";

export type Props = {
  onConfirm: () => void
  text: string
  show: boolean
  onClose: () => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} children={props.children} />;
});

export default function ConfirmDialog(props: Props) {
  const closeDialog = () => {
    props.onClose()
  }

  return (
    <>
      <Dialog
        fullScreen
        open={props.show}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}
          style={{backgroundColor: "#6750A4"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.text}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Button color="secondary" onClick={closeDialog}>
            Отмена
          </Button>

          <LoadingButton color="primary" onClick={() => {
            props.onConfirm()
            closeDialog()
          }}>
            Да
          </LoadingButton>
        </DialogContent>
      </Dialog>

    </>
  )
}
