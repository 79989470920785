export function changeCurrentTimeInCurrentMonth(date: Date): Date {
  if (date.getFullYear() === new Date().getFullYear() &&
    date.getMonth() === new Date().getMonth()) {
    date = new Date()
  }
  return date
}

export function addMonths(date: Date, months: number): Date {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}
