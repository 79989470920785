import React, {useState} from "react";
import {useAuthContext} from "../logic/AuthContext";
import {Pocket,} from "../logic/types";
import PocketListElement from "../Components/PocketListElement";
import LoginSuggestion from "../Components/LoginSuggestion";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import CreatePocketDialog from "../Components/CreatePocketDialog";
import Typography from "@mui/material/Typography";
import useGlobalSelectedMonth from "../hooks/useGlobalSelectedMonth";
import MonthSelector from "../Components/MonthSelector";
import useMonthDetails from "../hooks/useMonthDetails";
import {geldTheme} from "../style/geldTheme";

export default function PocketsPage(): JSX.Element {
  const {isAuthenticated} = useAuthContext()

  const [showCreatePocketDialog, setShowCreatePocketDialog] = useState(false)
  const [selectedMonth, setSelectedMonth] = useGlobalSelectedMonth()
  const [monthDetails, fetchMonthDetails] = useMonthDetails({selectedMonth})

  async function onChangeSelectedMonth(date: Date) {
    setSelectedMonth(date)
    await fetchMonthDetails(date)
  }

  function renderPage() {
    function pocketToTagTR(pocket: Pocket, index: number): JSX.Element {
      return (
        <PocketListElement
          key={pocket.id}
          pocket={pocket}
          index={index}
          onDeletedPocket={fetchMonthDetails}
          onChangedPocket={fetchMonthDetails}
          pockets={monthDetails.pockets}
        />
      )
    }

    const pocketsTR = monthDetails.pockets.map(pocketToTagTR)

    return (
      <ThemeProvider theme={geldTheme}>
        <Box style={{maxWidth: 600, margin: 'auto'}}>
          <IconButton
            color="primary"
            sx={{ml: 2}}
            onClick={() => setShowCreatePocketDialog(true)}
          >
            <AddIcon fontSize="large"/>
          </IconButton>

          <CreatePocketDialog
            show={showCreatePocketDialog}
            onClose={() => setShowCreatePocketDialog(false)}
            onAddedPocket={fetchMonthDetails}
            onChangedPocket={fetchMonthDetails}
          />

          <MonthSelector
            style={{float: 'right', padding: '8px'}}
            selectedMonth={selectedMonth}
            onChangeSelectedMonth={onChangeSelectedMonth}
          />

          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Баланс: {monthDetails.balance}
          </Typography>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Общий баланс: {monthDetails.totalBalance}
          </Typography>

          <TableContainer component={Paper}>
            <Table
              sx={{minWidth: devicePixelRatio}}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Название</TableCell>
                  <TableCell align="center">Баланс</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {pocketsTR}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <div>
      {isAuthenticated ? renderPage() : LoginSuggestion()}
    </div>
  );
}

