import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slide,
  Stack,
  TextField
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent, useEffect, useState } from "react";
import { apiAddIntent, apiChangeIntent, apiDeleteIntent, apiIgnoreIntent, } from "../logic/requests";
import { Intent, IntentRequest, MoneyColor, Pocket } from "../logic/types";
import { LoadingButton } from "@mui/lab";

export type Props = {
  onAddedIntent?: () => void
  onChangedIntent?: () => void
  onDeletedIntent?: () => void
  show: boolean
  onClose: () => void
  intent?: Intent
  moneyColor?: MoneyColor
  selectedMonth: Date
  pockets: Array<Pocket>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} children={props.children} />;
});

export default function IntentDialog(props: Props) {
  const closeDialog = () => {
    props.onClose()
  }

  useEffect(() => {
    if (!props.show) return

    if (props.intent) {
      setAmount(props.intent.amount.toString())
      setDescription(props.intent.description)
      setPaymentData(getDatePickerString(new Date(props.intent.paymentDate)))
      setIsExpense(props.intent.color === MoneyColor.Expense)
      setPeriodInMonths(props.intent.periodInMonths)

      const finishDate = props.intent.finishDate
      setFinishDate(finishDate ? finishDate.substring(0, 10) : "")
      setFromPocket(props.intent.fromPocketID?.toString() ?? '')
      setToPocket(props.intent.toPocketID?.toString() ?? '')
    } else {
      setAmount("")
      setDescription("")
      setPaymentData(getDatePickerString(props.selectedMonth))
      setIsExpense(props.moneyColor === MoneyColor.Expense)
      setPeriodInMonths(0)
      setFinishDate("")
      setFromPocket("")
      setToPocket("")
    }
  }, [props.show, props.intent, props.moneyColor, props.selectedMonth])

  const [amount, setAmount] = useState("")
  const [description, setDescription] = useState("")
  const [paymentDate, setPaymentData] = useState(getDatePickerString(props.selectedMonth))
  const [isExpense, setIsExpense] = useState(true)
  const [periodInMonths, setPeriodInMonths] = useState(0)
  const [finishDate, setFinishDate] = useState<string>("")
  const [fromPocket, setFromPocket] = useState('')
  const [toPocket, setToPocket] = useState('')

  function getDatePickerString(date: Date): string {
    let month = ('00' + (date.getMonth() + 1)).substr(-2)
    let day = ('00' + date.getDate()).substr(-2)
    return `${date.getFullYear()}-${month}-${day}`
  }

  async function onAddOrChange() {
    if (props.intent)
      await onChangeIntent(props.intent.id)
    else
      await onAddIntent()
  }

  async function onAddIntent() {
    console.log('onAddIntent')

    console.log(`paymentDate ${paymentDate}. periodInMonth ${periodInMonths}`)
    const request: IntentRequest = {
      amount: parseInt(amount),
      color: isExpense ? MoneyColor.Expense : MoneyColor.Income,
      paymentDate: new Date(paymentDate).toISOString(),
      finishDate: finishDate ? new Date(finishDate).toISOString() : undefined,
      periodInMonths: periodInMonths,
      description: description,
      fromPocketID: fromPocket !== "" ? parseInt(fromPocket) : undefined,
      toPocketID: toPocket !== "" ? parseInt(toPocket) : undefined,
    }

    const added = await apiAddIntent(request)
    if (added) {
      if (props.onAddedIntent)
        props.onAddedIntent()
      closeDialog()
    }
  }

  async function onChangeIntent(intentID: number) {
    console.log('STEP onChangeIntent')

    const request: IntentRequest = {
      amount: parseInt(amount),
      color: isExpense ? MoneyColor.Expense : MoneyColor.Income,
      paymentDate: new Date(paymentDate).toISOString(),
      finishDate: finishDate ? new Date(finishDate).toISOString() : undefined,
      periodInMonths: periodInMonths,
      description: description,
      fromPocketID: fromPocket !== "" ? parseInt(fromPocket) : undefined,
      toPocketID: toPocket !== "" ? parseInt(toPocket) : undefined,
    }

    const changed = await apiChangeIntent(intentID, request)
    if (changed) {
      if (props.onChangedIntent)
        props.onChangedIntent()
      closeDialog()
    }
  }

  async function onDeleteIntent() {
    console.log('onDeleteIntent')

    const intentID = props.intent?.id
    if (!intentID) {
      console.log('onDeleteIntent: intentID is undefined')
      return
    }

    const deleted = await apiDeleteIntent(intentID, props.selectedMonth)
    if (deleted) {
      if (props.onDeletedIntent)
        props.onDeletedIntent()
      closeDialog()
    }
  }

  async function onIgnoreIntent() {
    console.log('onIgnoreIntent')

    const intentID = props.intent?.id
    if (!intentID) {
      console.log('onIgnoreIntent: intentID is undefined')
      return
    }

    const deleted = await apiIgnoreIntent(intentID, props.selectedMonth)
    if (deleted) {
      if (props.onDeletedIntent)
        props.onDeletedIntent()
      closeDialog()
    }
  }

  function handleChangeAmount(event: ChangeEvent<HTMLInputElement>) {
    setAmount(event.target.value)
  }

  function handleChangePeriod(event: ChangeEvent<HTMLInputElement>) {
    setPeriodInMonths(parseInt(event.target.value))
  }

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    setDescription(event.target.value)
  }

  function handleChangeFinishDate(event: ChangeEvent<HTMLInputElement>) {
    setFinishDate(event.target.value)
  }

  function handleChangePaymentDate(event: ChangeEvent<HTMLInputElement>) {
    setPaymentData(event.target.value)
  }

  function switchMoneyColor() {
    setIsExpense(!isExpense)
  }

  function validateForm(): boolean {
    return parseInt(amount) > 0;
  }

  const handleChangeFromPocket = (event: SelectChangeEvent) => {
    setFromPocket(event.target.value as string);
  };

  const handleChangeToPocket = (event: SelectChangeEvent) => {
    setToPocket(event.target.value as string);
  };

  function mapPocketToMenuItem(pocket: Pocket): JSX.Element {
    return (
      <MenuItem key={pocket.id} value={pocket.id}>{pocket.title}</MenuItem>
    )
  }

  return (
    <Dialog
      fullScreen
      open={props.show}
      onClose={closeDialog}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{backgroundColor: "#6750A4"}}
        sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            запланирована трата
          </Typography>

          <LoadingButton color="inherit"
                  onClick={onAddOrChange}
                  disabled={!validateForm()}>
            {props.intent ? 'Изменить' : 'Добавить'}
          </LoadingButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Stack component="form" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              autoFocus
              label="Сумма"
              type="number"
              onChange={handleChangeAmount}
              value={amount}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Дата начала"
              type="date"
              onChange={handleChangePaymentDate}
              value={paymentDate}
            />

            <TextField
              label="Дата окончания"
              InputLabelProps={{shrink: true}}
              sx={{minWidth: '120px'}}
              type="date"
              onChange={handleChangeFinishDate}
              value={finishDate}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Переодичность"
              type="number"
              onChange={handleChangePeriod}
              value={periodInMonths}
            />
            <Button
              variant='outlined'
              onClick={switchMoneyColor}
            >
              {isExpense ? 'Расход' : 'Приход'}
            </Button>
          </Stack>

          <TextField
            label="Описание"
            type="text"
            onChange={handleChangeDescription}
            value={description}
          />

          <Box>
            <Button variant="text" onClick={() => setDescription("Аренда")}>
              Аренда
            </Button>

            <Button variant="text" onClick={() => setDescription("Сейф")}>
              Сейф
            </Button>

            <Button variant="text" onClick={() => setDescription("Развлечения")}>
              Развлечения
            </Button>

            <Button variant="text" onClick={() => setDescription("Обучение")}>
              Обучение
            </Button>

            <Button variant="text" onClick={() => setDescription("Коты")}>
              Коты
            </Button>
          </Box>

          <FormControl fullWidth>
            <InputLabel>Из кошелька</InputLabel>
            <Select
              value={fromPocket}
              label="Из кошелька"
              onChange={handleChangeFromPocket}
            >
              <MenuItem value={''}><i>Main</i></MenuItem>
              {props.pockets.map(mapPocketToMenuItem)}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>В кошелек</InputLabel>
            <Select
              value={toPocket}
              label="В кошелек"
              onChange={handleChangeToPocket}
            >
              <MenuItem value={''}><i>Main</i></MenuItem>
              {props.pockets.map(mapPocketToMenuItem)}
            </Select>
          </FormControl>

        </Stack>
      </DialogContent>

      <DialogActions>
        {props.intent && (
          <>
            <Button
              variant="outlined"
              color="error"
              onClick={onDeleteIntent}
            >
              Удалить
            </Button>

            <Button
              variant="outlined"
              onClick={onIgnoreIntent}
            >
              Игнорировать
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
