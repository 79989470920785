export enum MoneyColor {
  Income = "income",
  Expense = "expense"
}

export type Transaction = {
  id: number
  amount: number
  color?: MoneyColor
  description: string
  paidAt: string
  intentID?: number
  fromPocketID?: number
  toPocketID?: number
}

export type TransactionRequest = {
  amount: number
  color?: MoneyColor
  paidAt: string
  description: string
  intentID?: number
  fromPocketID?: number
  toPocketID?: number
}

export type Intent = {
  id: number
  amount: number
  color?: MoneyColor
  description: string
  paymentDate: string
  finishDate?: string
  periodInMonths: number
  fromPocketID?: number
  toPocketID?: number
}

export type IntentRequest = {
  amount: number
  color?: MoneyColor
  paymentDate: string
  finishDate?: string
  periodInMonths: number
  description: string
  fromPocketID?: number
  toPocketID?: number
}

export type Pocket = {
  id: number
  title: string
  balance: number
}

export type PocketRequest = {
  title: string
}

export interface PocketDetails extends Pocket {
  transactions: Array<Transaction>
}

export type MonthDetails = {
  balance: number
  totalBalance: number
  everydayBaseBudget: number
  intents: Array<Intent>
  transactions: Array<Transaction>
  pockets: Array<Pocket>
}

export const ZeroMonthDetails = {
  balance: 0,
  totalBalance: 0,
  everydayBaseBudget: 0,
  intents: [],
  transactions: [],
  pockets: []
}

export type TodayDetails = {
  freeMoneyForToday: number
  everydayBaseBudget: number
  todayExpenses: Array<Transaction>
  pockets: Array<Pocket>

}

export type LoginRequest = {
  email: string,
  password: string
}


export type LoginResponse = {
  accessToken: string
}
