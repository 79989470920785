import React, { ChangeEvent, useState } from "react";
import { apiAddPocket } from "../logic/requests";
import { PocketRequest } from "../logic/types";
import { Dialog, DialogContent, Slide, Stack, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import { LoadingButton } from "@mui/lab";

export type Props = {
  onAddedPocket?: () => void
  onChangedPocket?: () => void
  show: boolean
  onClose: () => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} children={props.children}/>;
});

export default function CreatePocketDialog(props: Props) {
  const closeDialog = () => {
    props.onClose()
  }

  const [title, setTitle] = useState("")

  async function onAddPocket() {
    console.log('onAddPocket')

    const request: PocketRequest = {
      title: title,
    }

    const added = await apiAddPocket(request)
    if (added) {
      if (props.onAddedPocket)
        props.onAddedPocket()
      closeDialog()
    }
  }

  function handleChangeTitle(event: ChangeEvent<HTMLInputElement>) {
    setTitle(event.target.value)
  }

  function validateForm(): boolean {
    return title.length > 0;
  }

  return (
    <Dialog
      fullScreen
      open={props.show}
      onClose={closeDialog}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{ backgroundColor: "#6750A4" }}
        sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            создать кошелёк
          </Typography>
          <LoadingButton color="inherit"
                         onClick={onAddPocket}
                         disabled={!validateForm()}>
            Добавить
          </LoadingButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Stack component="form" spacing={2}>
          <TextField
            autoFocus
            label="Название"
            type="text"
            onChange={handleChangeTitle}
            value={title}
          />
        </Stack>
      </DialogContent>

    </Dialog>
  );
}
