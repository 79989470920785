import {Pocket} from "./types";

export function formatAmount(amount: number): string {
  return `€${Math.floor(amount)}`
}

export function buildPocketInfo(
  pockets: Array<Pocket>,
  fromPocketId?: number,
  toPocketId?: number,
): string | undefined {

  const defaultPocket: string = "покет"
  const fromPocket: string = (fromPocketId ? pockets.find(pocket => pocket.id === fromPocketId)?.title : defaultPocket) ?? defaultPocket
  const toPocket: string = (toPocketId ? pockets.find(pocket => pocket.id === toPocketId)?.title : defaultPocket) ?? defaultPocket

  if (fromPocketId && toPocketId) {
    return `из покета ${fromPocket} в покет ${toPocket}`
  }
  if (fromPocketId) {
    return `из покета ${fromPocket}`
  }
  if (toPocketId) {
    return `в покет ${toPocket}`
  }
  return undefined
}

export function loadIsLoggedIn(): boolean {
  return loadAccessToken() != null
}

export function removeAccessToken() {
  localStorage.removeItem('accessToken');
}

export function saveAccessToken(accessToken: string) {
  localStorage.setItem('accessToken', accessToken)
}

export function loadAccessToken(): string | null {
  return localStorage.getItem('accessToken')
}
