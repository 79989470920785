import {Stack} from "@mui/material";
import React, {ReactElement, useState} from "react";
import TransactionDialog from "./TransactionDialog";
import ConfirmDialog from "./ConfirmDialog";
import {apiDeleteTransaction} from "../logic/requests";
import {MoneyColor, Pocket, Transaction} from "../logic/types";
import IconButton from "@mui/material/IconButton/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from "@mui/material/Typography";
import {buildPocketInfo} from "../logic/helpers";
import {transactionMainColor} from "../logic/transaction";

export type Props = {
  onChangedTransaction: () => void
  onDeletedTransaction: () => void
  transaction: Transaction
  pockets: Array<Pocket>
}

export default function TransactionListElement(props: Props) {
  const [showTransactionDialog, setShowTransactionDialog] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  async function deleteTransaction(transactionID: number) {
    console.log("delete transaction with id " + transactionID)
    const deleted = await apiDeleteTransaction(transactionID)
    if (deleted) {
      props.onDeletedTransaction()
    }
  }

  const amountView = (): ReactElement => {
    const mainColor = transactionMainColor(props.transaction)
    if (mainColor === MoneyColor.Expense) {
      return <div>-{props.transaction.amount}</div>
    }

    if (mainColor === MoneyColor.Income) {
      return <div>+{props.transaction.amount}</div>
    }

    return <div>{props.transaction.amount}</div>
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" style={{width: '100%'}}>
      <Stack direction="column" justifyContent="start" alignItems="start"
             style={{minHeight: '30px', width: '100%', maxWidth: '440px'}}>
        <Stack direction="row" justifyContent="center" alignItems="center" style={{width: '100%'}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            textAlign="left"
            // style={{ minWidth: '240px' }}
            // sx={{ width: '100%' }}
            style={{width: '100%'}}
          >
            <div style={{width: '100%'}}>{props.transaction.description}</div>
            {amountView()}
          </Stack>

          <div style={{width: '20px'}}/>

          <IconButton aria-label="edit"
                      color="primary"
                      style={{color: "#6750A4"}}
                      size="small"
                      onClick={() => setShowTransactionDialog(true)}>
            <EditIcon fontSize="small"/>
          </IconButton>

          <IconButton aria-label="delete"
                      size="small"
                      onClick={() => setShowConfirmDialog(true)}>
            <DeleteIcon fontSize="small"/>
          </IconButton>

        </Stack>

        <Typography variant="caption" style={{marginTop: '-10px', opacity: 0.5}}>
          {buildPocketInfo(props.pockets, props.transaction.fromPocketID, props.transaction.toPocketID)}
        </Typography>

        <ConfirmDialog
          show={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => deleteTransaction(props.transaction.id)}
          text={"Вы действительно хотите удалить трату?"}/>
        {/*{props.todayExpense.description}: {props.todayExpense.amount}{" "}*/}

        <TransactionDialog
          open={showTransactionDialog}
          onClose={() => setShowTransactionDialog(false)}
          transaction={props.transaction}
          onChangedTransaction={props.onChangedTransaction}
          date={new Date(props.transaction.paidAt)}
          pockets={props.pockets}
        />
      </Stack>
    </Stack>
  );
}
