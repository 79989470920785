import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slide,
  Stack, TextField, ThemeProvider
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React, { ChangeEvent, useEffect, useState } from "react";
import { apiAddTransaction, apiChangeTransaction } from "../logic/requests";
import { MoneyColor, Pocket, Transaction, TransactionRequest } from "../logic/types";
import { createTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

interface Props {
  onAddedTransaction?: () => void
  onChangedTransaction?: () => void
  transaction?: Transaction
  open: boolean
  onClose: () => void
  date: Date
  pockets: Array<Pocket>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} children={props.children} />;
});

export default function TransactionDialog(props: Props) {

  const handleClose = () => {
    props.onClose()
  }

  useEffect(() => {
    if (!props.open) return

    if (props.transaction) {
      setAmount(props.transaction.amount.toString())
      setDescription(props.transaction.description)
      setDate(props.transaction.paidAt.substring(0, 10))
      setIsExpense(props.transaction.color === MoneyColor.Expense)
      setFromPocket(props.transaction.fromPocketID?.toString() ?? '')
      setToPocket(props.transaction.toPocketID?.toString() ?? '')
    } else {
      setAmount('')
      setDescription('')
      setDate(getDatePickerString(props.date))
      setIsExpense(true)
      setFromPocket('')
      setToPocket('')
    }
  }, [props.open, props.transaction, props.date])


  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(getDatePickerString(props.date));
  const [isExpense, setIsExpense] = useState(true);
  const [fromPocket, setFromPocket] = useState('');
  const [toPocket, setToPocket] = useState('');

  const theme = createTheme({
    palette: {
      primary: {
        main: '#6750A4',
      },
      background: {
        paper: '#FFFBFE',
        default: '#7D5260'
      }
    },
  });

  function getDatePickerString(date: Date): string {
    let month = ('00' + (date.getMonth() + 1)).substr(-2)
    let day = ('00' + date.getDate()).substr(-2)
    return `${date.getFullYear()}-${month}-${day}`
  }

  async function onClick() {
    if (props.transaction)
      await onChangeTransaction(props.transaction.id)
    else
      await onAddTransaction()
  }

  async function onAddTransaction() {
    console.log('STEP onAddTransaction')

    const request: TransactionRequest = {
      amount: parseInt(amount),
      color: isExpense ? MoneyColor.Expense : MoneyColor.Income,
      paidAt: new Date(date).toISOString(),
      description: description,
      fromPocketID: fromPocket !== "" ? parseInt(fromPocket) : undefined,
      toPocketID: toPocket !== "" ? parseInt(toPocket) : undefined,
    }

    const added = await apiAddTransaction(request)
    if (added) {
      if (props.onAddedTransaction)
        props.onAddedTransaction()
      handleClose()
    }
  }

  async function onChangeTransaction(transactionID: number) {
    console.log('STEP onChangeTransaction')

    const request: TransactionRequest = {
      amount: parseInt(amount),
      color: props.transaction?.color,
      intentID: props.transaction?.intentID,
      paidAt: new Date(date).toISOString(),
      description: description,
      fromPocketID: fromPocket !== "" ? parseInt(fromPocket) : undefined,
      toPocketID: toPocket !== "" ? parseInt(toPocket) : undefined,
    }

    const changed = await apiChangeTransaction(transactionID, request)
    if (changed) {
      if (props.onChangedTransaction)
        props.onChangedTransaction()
      handleClose()
    }
  }

  function handleChangeAmount(event: ChangeEvent<HTMLInputElement>) {
    setAmount(event.target.value)
  }

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    setDescription(event.target.value)
  }

  function handleChangeDate(event: ChangeEvent<HTMLInputElement>) {
    setDate(event.target.value)
  }

  const handleChangeFromPocket = (event: SelectChangeEvent) => {
    setFromPocket(event.target.value as string);
  };

  const handleChangeToPocket = (event: SelectChangeEvent) => {
    setToPocket(event.target.value as string);
  };

  function validateForm(): boolean {
    return parseInt(amount) > 0;
  }

  function mapPocketToMenuItem(pocket: Pocket): JSX.Element {
    return (
      <MenuItem key={pocket.id} value={pocket.id}>{pocket.title}</MenuItem>
    )
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <ThemeProvider theme={theme}>
        <AppBar
          style={{ backgroundColor: "#6750A4" }}
          sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Транзакция
            </Typography>
            <LoadingButton color="inherit" onClick={onClick} disabled={!validateForm()}>
              {props.transaction ? 'Применить' : 'Добавить'}
            </LoadingButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Stack component="form" spacing={2} onSubmit={onAddTransaction}>
            <Stack direction="row" justifyContent="space-between">
              <TextField
                autoFocus
                label="Сумма"
                type="number"
                onChange={handleChangeAmount}
                value={amount}
              />

              <TextField
                label="Дата"
                type="date"
                onChange={handleChangeDate}
                value={date}
              />
            </Stack>

            <TextField
              label="Описание"
              type="text"
              onChange={handleChangeDescription}
              value={description}
            />

            <Box>
              <Button variant="text" onClick={() => setDescription("REWE")}>
                REWE
              </Button>

              <Button variant="text" onClick={() => setDescription("DM")}>
                DM
              </Button>

              <Button variant="text" onClick={() => setDescription("Bars")}>
                Bars
              </Button>

              <Button variant="text" onClick={() => setDescription("Amazon")}>
                Amazon
              </Button>

              <Button variant="text" onClick={() => setDescription("Cats")}>
                Cats
              </Button>
            </Box>

            <FormControl fullWidth>
              <InputLabel>Из кошелька</InputLabel>
              <Select
                value={fromPocket}
                label="Из кошелька"
                onChange={handleChangeFromPocket}
              >
                <MenuItem value={''}><i>Main</i></MenuItem>
                {props.pockets.map(mapPocketToMenuItem)}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>В кошелек</InputLabel>
              <Select
                value={toPocket}
                label="В кошелек"
                onChange={handleChangeToPocket}
              >
                <MenuItem value={''}><i>Main</i></MenuItem>
                {props.pockets.map(mapPocketToMenuItem)}
              </Select>
            </FormControl>

          </Stack>
        </DialogContent>
      </ThemeProvider>
    </Dialog>
  );
}
