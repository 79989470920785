import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, Fab } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import TransactionListElement from "./TransactionListElement";
import { Pocket, Transaction } from "../logic/types";
import TransactionDialog from "./TransactionDialog";

interface Props {
  show: boolean
  onClose: () => void
  onChangedTransaction: () => void
  onDeletedTransaction: () => void
  onAddedTransactionButton: () => void
  transactions: Array<Transaction>
  date: Date
  pockets: Array<Pocket>
}

export default function DailyTransactionsDialog(props: Props) {
  const [openAddTransactionDialog, setOpenAddTransactionDialog] = useState(false);

  const listDailyTransactions = props.transactions.map(transaction =>
    <TransactionListElement
      key={transaction.id}
      onChangedTransaction={props.onChangedTransaction}
      onDeletedTransaction={props.onDeletedTransaction}
      transaction={transaction}
      pockets={props.pockets}
    />
  )

  return (
    <Dialog
      fullScreen
      open={props.show}
      onClose={props.onClose}
    >
      <AppBar
        style={{ backgroundColor: "#6750A4" }}
        sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Потрачено на сегодня
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <ul>{listDailyTransactions}</ul>
        <Fab
          color="primary"
          onClick={() => setOpenAddTransactionDialog(true)}
          style={{ marginRight: '20px', backgroundColor: "#6750A4" }}
        >
          <AddIcon fontSize="large"/>
        </Fab>

        <TransactionDialog
          open={openAddTransactionDialog}
          onClose={() => setOpenAddTransactionDialog(false)}
          onAddedTransaction={props.onAddedTransactionButton}
          date={props.date}
          pockets={props.pockets}
        />

      </DialogContent>

    </Dialog>
  );
}