import IconButton from "@mui/material/IconButton";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import React, { useState } from "react";
import ConfirmDialog from "./ConfirmDialog";


export type Props = {
  onLogoutButton: () => void
}

export default function LogoutButton(props: Props) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <IconButton
        edge="end"
        color="inherit"
        onClick={() => setShowDialog(true)}
      >
        <LogoutOutlinedIcon />
      </IconButton>

      <ConfirmDialog
        show={showDialog}
        onConfirm={props.onLogoutButton}
        onClose={() => setShowDialog(false)}
        text={"Вы действительно хотите выйти?"} />

    </>
  );
}
