import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, {ReactElement, useState} from "react";
import {useAuthContext} from "../logic/AuthContext";
import IntentDialog from "../Components/IntentDialog";
import {Intent, MoneyColor, Transaction} from "../logic/types";
import IntentListElement from "../Components/IntentListElement";
import LoginSuggestion from "../Components/LoginSuggestion";
import {getIntentPaidTransactions, transactionMainColor} from "../logic/transaction";
import useGlobalSelectedMonth from "../hooks/useGlobalSelectedMonth";
import MonthSelector from "../Components/MonthSelector";
import useMonthDetails from "../hooks/useMonthDetails";
import {geldTheme} from "../style/geldTheme";
import {intentMainColor} from "../logic/intents";
import Typography from "@mui/material/Typography";

export default function IntentsPage(): JSX.Element {
  const {isAuthenticated} = useAuthContext()

  const [showDialog, setShowDialog] = useState(false)
  const [selectedMonth, setSelectedMonth] = useGlobalSelectedMonth()
  const [monthDetails, fetchMonthDetails] = useMonthDetails({selectedMonth})

  async function onChangeSelectedMonth(date: Date) {
    setSelectedMonth(date)
    await fetchMonthDetails(date)
  }

  function renderDetails() {

    function mapIntentToListElement(intent: Intent, index: number): JSX.Element {
      return (
        <IntentListElement
          key={intent.id}
          onChangedIntent={fetchMonthDetails}
          onDeletedIntent={fetchMonthDetails}
          onPaidMoney={fetchMonthDetails}
          intent={intent}
          index={index}
          transactions={getIntentPaidTransactions(monthDetails, intent)}
          onChangedPaidTransaction={fetchMonthDetails}
          onDeletedPaidTransaction={fetchMonthDetails}
          selectedMonth={selectedMonth}
          pockets={monthDetails.pockets}
        />
      )
    }

    function calculateExpensesSum(intents: Array<Intent>): number {
      // Или
      // return intents.filter(onlyPlannedExpenses).reduce((sum, intent) => sum + intent.amount, 0)
      // Или
      // return intents.filter(onlyPlannedExpenses).map((intent) => intent.amount).reduce((sum, amount) => sum + amount)

      let sum = 0

      for (let i = 0; i < intents.length; i++) {
        if (intentMainColor(intents[i]) === MoneyColor.Expense) {
          sum = sum + intents[i].amount
        }
      }

      return sum
    }

    function sumIntentExpensesTransactions(): number {
      const transactions = monthDetails.transactions
      let sum = 0

      for (let i = 0; i < transactions.length; i++) {
        if (transactions[i].intentID && transactionMainColor(transactions[i]) === MoneyColor.Expense) {
          sum = sum + transactions[i].amount
        }
      }

      return sum
    }

    function sumIntentTransactions(intentID: number): number {
      const transactions = monthDetails.transactions
      let sum = 0

      for (let i = 0; i < transactions.length; i++) {
        if (transactions[i].intentID === intentID) {
          sum = sum + transactions[i].amount
        }
      }

      return sum
    }

    function sortIntents(intent1: Intent, intent2: Intent): number {
      const byPaid = sortByPaid(intent1, intent2)
      if (byPaid !== 0) {
        return byPaid
      }
      return sortByAmount(intent1, intent2)
    }

    function sortByAmount(intent1: Intent, intent2: Intent): number {
      return intent2.amount - intent1.amount
    }

    function sortByPaid(intent1: Intent, intent2: Intent): number {
      const paid1 = sumIntentTransactions(intent1.id) >= intent1.amount
      const paid2 = sumIntentTransactions(intent2.id) >= intent2.amount

      if (paid1 && !paid2) {
        return 1
      }
      if (!paid1 && paid2) {
        return -1
      }
      return 0
    }

    function mapExpensesToReactElements(intents: Array<Intent>): Array<ReactElement> {
      // Или
      // return monthDetails.intents.filter(onlyPlannedExpenses).map(mapIntentToListElement)
      intents = intents.sort(sortIntents)

      let reactElements: Array<ReactElement> = []

      for (let i = 0; i < intents.length; i++) {
        if (intentMainColor(intents[i]) === MoneyColor.Expense) {
          const reactElement = mapIntentToListElement(intents[i], i)
          reactElements.push(reactElement)
        }
      }

      return reactElements
    }

    const plannedExpensesTR = mapExpensesToReactElements(monthDetails.intents)


    return (
      <ThemeProvider theme={geldTheme}>
        <Box style={{maxWidth: 750, margin: 'auto'}}>

          {/*<div>{monthDetails.totalBalance}</div>*/}
          {/*<div>{monthDetails.balance}</div>*/}

          <Stack direction='row' justifyContent="space-between" alignItems="center">
            <IconButton
              color="primary"
              sx={{ml: 2}}
              onClick={() => setShowDialog(true)}
            >
              <AddIcon fontSize="large"/>
            </IconButton>

            <Stack direction='row' alignItems="center" spacing={1}>
              <Typography component="div">
                Дневной бюджет:
              </Typography>
              <Typography variant="h6" component="div">
                {monthDetails.everydayBaseBudget}
              </Typography>
            </Stack>

            <MonthSelector
              style={{float: 'right', padding: '8px'}}
              selectedMonth={selectedMonth}
              onChangeSelectedMonth={onChangeSelectedMonth}
            />
          </Stack>

          <TableContainer
            component={Paper}
            style={{backgroundColor: "#FFFBFE"}}>
            <Table
              sx={{minWidth: devicePixelRatio}}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Планируемая трата</TableCell>
                  <TableCell align="center">Сумма</TableCell>
                  <TableCell align="center">Статус</TableCell>
                  <TableCell align="center">Оплачено</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {plannedExpensesTR}
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell align="center">ИТОГО</TableCell>
                  <TableCell align="center">
                    {calculateExpensesSum(monthDetails.intents)}
                  </TableCell>
                  <TableCell align="center"/>
                  <TableCell align="center">
                    {sumIntentExpensesTransactions()}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>

        <IntentDialog
          show={showDialog}
          onClose={() => setShowDialog(false)}
          onAddedIntent={fetchMonthDetails}
          moneyColor={MoneyColor.Expense}
          selectedMonth={selectedMonth}
          pockets={monthDetails.pockets}
        />
      </ThemeProvider>
    )
  }

  return (
    <div>
      {isAuthenticated ? renderDetails() : LoginSuggestion()}
    </div>
  );
}