import './LoginPage.css';
import {LoadingButton} from "@mui/lab";
import {Stack, TextField} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../logic/AuthContext";
import {apiLogin} from "../logic/requests";
import {saveAccessToken} from "../logic/helpers";
import {LoginRequest} from "../logic/types";

export default function LoginPage() {
  const {setIsAuthenticated} = useAuthContext();

  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value)
  }

  async function onSubmit() {
    console.log('STEP onSubmit')
    console.log(`login started email ${email} password ${password}`);

    const request: LoginRequest = {
      email: email,
      password: password
    };

    setIsLoading(true) // Начать показывать колесико
    const response = await apiLogin(request)
    if (response) {
      saveAccessToken(response.accessToken)
      setIsAuthenticated(true)
      navigate("/")
    }
    setIsLoading(false) // Закончить показыать колесико
  }

  return (
    <div className="Login">
      <h3>
        Спасем деньги!
      </h3>

      <Stack component="form" onSubmit={onSubmit} spacing={2}>
        <TextField
          required
          autoFocus
          label="Емейл"
          type="email"
          onChange={handleEmailChange}
          value={email}
        />

        <TextField
          required
          label="Пароль"
          type="password"
          onChange={handlePasswordChange}
          value={password}
        />

        <LoadingButton
          variant="outlined"
          onClick={onSubmit}
          loading={isLoading}
        >
          Вход
        </LoadingButton>
      </Stack>
    </div>
  );
}
