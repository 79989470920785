import { Dialog, DialogContent, Slide } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import TransactionListElement from "./TransactionListElement";
import { Pocket, Transaction } from "../logic/types";

type Props = {
  show: boolean
  onClose: () => void
  onChangedTransaction: () => void
  onDeletedTransaction: () => void
  transactions: Array<Transaction>
  pockets: Array<Pocket>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} children={props.children} />;
});


export default function PaidTransactionsDialog(props: Props) {
  useEffect(() => {
    if (props.transactions.length === 0)
      props.onClose()
  }, [props])

  const listElements = props.transactions.map(transaction =>
    <TransactionListElement
      transaction={transaction}
      key={transaction.id}
      onChangedTransaction={props.onChangedTransaction}
      onDeletedTransaction={props.onDeletedTransaction}
      pockets={props.pockets}
    />
  )


  return (
    <Dialog
      fullScreen
      open={props.show}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{backgroundColor: "#6750A4"}}
        sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color='inherit'
            onClick={props.onClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Проведено транзакций
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {listElements}
      </DialogContent>

    </Dialog>
  );
}
