import AddIcon from "@mui/icons-material/Add";
import {Button, Fab, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAuthContext} from "../logic/AuthContext";
import TransactionDialog from "../Components/TransactionDialog";
import TransactionListElement from "../Components/TransactionListElement";
import {apiFetchTodayDetails} from "../logic/requests";
import {TodayDetails} from "../logic/types";
import Typography from "@mui/material/Typography";

export default function HomePage() {
  const {isAuthenticated} = useAuthContext()

  const [openAddTransactionDialog, setOpenAddTransactionDialog] = useState(false);

  const [todayDetail, setTodayDetails] = useState<TodayDetails>({
    freeMoneyForToday: 0,
    everydayBaseBudget: 0,
    todayExpenses: [],
    pockets: []
  });

  useEffect(() => {
    if (isAuthenticated) {
      fetchTodayDetails();
    }
  }, [isAuthenticated]);

  async function fetchTodayDetails() {
    console.log("fetchTodayDetails")
    const result = await apiFetchTodayDetails()
    if (result)
      setTodayDetails(result)
  }

  function renderLoginSuggestion() {
    return (
      <div className="lander">
        <p className="text-muted">Вы не залогинены</p>
        <div className="pt-3">
          <Button
            href="/login"
            size="large"
          >
            Войти
          </Button>
        </div>
      </div>
    )
  }

  function renderHome() {
    const todayExpenses = todayDetail.todayExpenses.map((expense) =>
      <TransactionListElement
        key={expense.id}
        onChangedTransaction={fetchTodayDetails}
        onDeletedTransaction={fetchTodayDetails}
        transaction={expense}
        pockets={todayDetail.pockets}
      />
    );

    let now = new Date()

    return (
      <Stack
        className="Home"
        justifyContent="space-between"
        style={{minHeight: 'calc(100vh - 80px)'}}>

        {/*<Container className="test" maxWidth="xs">*/}
        {/*  <div className="box">*/}
        {/*    <h2 className="item">*/}
        {/*      сегодня {now.getDate()}.{now.getMonth() + 1}*/}
        {/*    </h2>*/}
        {/*    <h1>*/}
        {/*      {todayDetail.freeMoneyForToday} €*/}
        {/*    </h1>*/}

        {/*    <ul>*/}
        {/*      <Stack spacing={1}>*/}
        {/*        {todayExpenses}*/}
        {/*      </Stack>*/}
        {/*    </ul>*/}
        {/*  </div>*/}

        {/*  <Stack direction="row" justifyContent="center">*/}

        {/*    <Fab*/}
        {/*      color="primary"*/}
        {/*      onClick={() => setOpenAddTransactionDialog(true)}*/}
        {/*      style={{ margin: '80px', backgroundColor: "#6750A4"}}*/}
        {/*    >*/}
        {/*      <AddIcon fontSize="large" />*/}
        {/*    </Fab>*/}
        {/*  </Stack>*/}

        {/*</Container>*/}

        <div className="box">
          <h2 className="item">
            сегодня {now.getDate()}.{now.getMonth() + 1}
          </h2>
          <Stack direction='column' justifyContent='center' alignItems='center'>
            <h1 className='center'>
              {todayDetail.freeMoneyForToday} €
            </h1>
            <Typography variant="caption" style={{marginTop: '-25px', opacity: 0.5}}>
              Дневной бюджет: {todayDetail.everydayBaseBudget} €
            </Typography>
          </Stack>

          <ul>
            <Stack spacing={1} style={{paddingLeft: "24px", paddingRight: "24px"}}>
              {todayExpenses}
            </Stack>
          </ul>
        </div>


        <Stack direction="row" justifyContent="center">

          <Fab
            color="primary"
            onClick={() => setOpenAddTransactionDialog(true)}
            style={{margin: '80px', backgroundColor: "#6750A4"}}
          >
            <AddIcon fontSize="large"/>
          </Fab>
        </Stack>

        <TransactionDialog
          open={openAddTransactionDialog}
          onClose={() => setOpenAddTransactionDialog(false)}
          onAddedTransaction={fetchTodayDetails}
          date={new Date()}
          pockets={todayDetail.pockets}
        />
      </Stack>

    )
  }

  return (
    <div>
      {isAuthenticated ? renderHome() : renderLoginSuggestion()}
    </div>
  )
}

