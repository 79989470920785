import { Stack } from "@mui/material";
import React, { useState } from "react";
import TransactionDialog from "./TransactionDialog";
import ConfirmDialog from "./ConfirmDialog";
import { apiDeleteTransaction } from "../logic/requests";
import { Pocket, Transaction } from "../logic/types";
import IconButton from "@mui/material/IconButton/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export type Props = {
  onChangedTransaction: () => void
  onDeletedTransaction: () => void
  transaction: Transaction
  pocket: Pocket
  pockets: Array<Pocket>
}

export default function PocketTransactionListElement(props: Props) {
  const [showTransactionDialog, setShowTransactionDialog] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  async function deleteTransaction(transactionID: number) {
    console.log("delete transaction with id " + transactionID)
    const deleted = await apiDeleteTransaction(transactionID)
    if (deleted) {
      props.onDeletedTransaction()
    }
  }

  return (
    <div>
      <Stack component="form" direction="row" justifyContent="center" alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ minWidth: '300px' }}
        >
          <div>{new Date(props.transaction.paidAt).toLocaleDateString()}</div>
          <div>{props.transaction.description}</div>
          <div><b>{props.transaction.toPocketID === props.pocket.id ? "+ " : "- "}</b>
            {props.transaction.amount}
          </div>
        </Stack>

        <div style={{ width: '20px' }}/>

        <IconButton aria-label="edit"
                    color="primary"
                    style={{ color: "#6750A4" }}
                    size="small"
                    onClick={() => setShowTransactionDialog(true)}>
          <EditIcon fontSize="small"/>
        </IconButton>

        <IconButton aria-label="delete"
                    size="small"
                    onClick={() => setShowConfirmDialog(true)}>
          <DeleteIcon fontSize="small"/>
        </IconButton>

      </Stack>

      <ConfirmDialog
        show={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => deleteTransaction(props.transaction.id)}
        text={"Вы действительно хотите удалить трату?"}/>
      {/*{props.todayExpense.description}: {props.todayExpense.amount}{" "}*/}

      <TransactionDialog
        open={showTransactionDialog}
        onClose={() => setShowTransactionDialog(false)}
        transaction={props.transaction}
        onChangedTransaction={props.onChangedTransaction}
        date={new Date(props.transaction.paidAt)}
        pockets={props.pockets}
      />
    </div>
  );
}
