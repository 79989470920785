import {useState} from "react";

let globalSelectedMonth = new Date()

export default function useGlobalSelectedMonth(): [Date, (value: Date) => void] {
  const [selectedMonth, setSelectedMonth] = useState(globalSelectedMonth)

  const setGlobalSelectedMonth = (value: Date) => {
    globalSelectedMonth = value
    setSelectedMonth(value)
  }

  return [selectedMonth, setGlobalSelectedMonth]
}
