import React, { ReactElement, useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Button, Dialog, DialogActions, DialogContent, Slide, Stack } from "@mui/material";
import { Pocket, Transaction } from "../logic/types";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import { apiDeletePocket, apiFetchPocketDetails } from "../logic/requests";
import ConfirmDialog from "./ConfirmDialog";
import ChangePocketDialog from "./ChangePocketDialog";
import PocketTransactionListElement from "./PocketTransactionListElement";

export type Props = {
  onChangedPocket: () => void
  onDeletedPocket: () => void
  show: boolean
  onClose: () => void
  pocket: Pocket
  pockets: Array<Pocket>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} children={props.children} />;
});

export default function PocketDetailsDialog(props: Props) {
  const closeDialog = () => {
    props.onClose()
  }

  const [showChangePocketDialog, setShowChangePocketDialog] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [pocketTransactions, setPocketTransactions] = useState<Array<Transaction>>([])

  async function fetchPocketDetails() {
    console.log('fetchPocketDetails')
    const result = await apiFetchPocketDetails(props.pocket.id)
    if (result) {
      setPocketTransactions(result.transactions)
    }
  }

  useEffect(() => {
    if (props.show) {
      fetchPocketDetails().catch(console.error)
    }
  }, [props.pocket, props.show])

  function mapTransactionToListElement(transaction: Transaction): ReactElement {
    return <PocketTransactionListElement
      key={transaction.id}
      onChangedTransaction={props.onChangedPocket}
      onDeletedTransaction={props.onChangedPocket}
      transaction={transaction}
      pocket={props.pocket}
      pockets={props.pockets}
    />
  }

  async function onDeletePocket() {
    console.log('onDeletePocket')

    const pocketID = props.pocket.id
    if (!pocketID) {
      console.log('onDeletePocket: pocketID is undefined')
      return
    }

    const deleted = await apiDeletePocket(pocketID)
    if (deleted) {
      props.onDeletedPocket()
      closeDialog()
    }
  }

  function onChangePocket() {
    props.onChangedPocket()
  }

  return (
    <Dialog
      open={props.show}
      onClose={closeDialog}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar
        style={{ backgroundColor: "#6750A4" }}
        sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.pocket.title}
          </Typography>

          {props.pocket && (
            <>
              <Button
                color="inherit"
                onClick={() => setShowConfirmDialog(true)}
              >
                Удалить
              </Button>

            </>
          )}

        </Toolbar>
      </AppBar>

      <ConfirmDialog
        onConfirm={onDeletePocket}
        text={"Вы действительно хотите удалить кошелек?"}
        show={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      />

      <DialogContent>
        <div>Баланс: <b>{props.pocket.balance}</b></div>

        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          Транзакции
        </Typography>
        <Stack>
          {pocketTransactions
            .sort((a, b) =>  new Date(b.paidAt).getTime() - new Date(a.paidAt).getTime())
            .map(mapTransactionToListElement)}
        </Stack>
      </DialogContent>

      <DialogActions>
        {props.pocket && (
          <>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setShowChangePocketDialog(true)}
            >
              Изменить
            </Button>

          </>
        )}

      </DialogActions>

      <ChangePocketDialog
        show={showChangePocketDialog}
        onClose={() => setShowChangePocketDialog(false)}
        onChangedPocket={onChangePocket}
        pocket={props.pocket}
      />

    </Dialog>
  )
}
