import { Link } from "react-router-dom";
import React from "react";

export default function LoginSuggestion(): JSX.Element {
  return (
    <div className="lander">
      <p className="text-muted">Вы не залогинены</p>
      <div className="pt-3">
        <Link to="/login" className="btn btn-info btn-lg mr-3">
          Login
        </Link>
      </div>
    </div>
  );
}
