import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, {useState} from "react";
import {useAuthContext} from "../logic/AuthContext";
import IntentDialog from "../Components/IntentDialog";
import {Intent, MoneyColor} from "../logic/types";
import IntentListElement from "../Components/IntentListElement";
import LoginSuggestion from "../Components/LoginSuggestion";
import {getIntentPaidTransactions} from "../logic/transaction";
import useGlobalSelectedMonth from "../hooks/useGlobalSelectedMonth";
import MonthSelector from "../Components/MonthSelector";
import useMonthDetails from "../hooks/useMonthDetails";
import {geldTheme} from "../style/geldTheme";
import {intentMainColor} from "../logic/intents";
import Typography from "@mui/material/Typography";

export default function IncomePage(): JSX.Element {
  const {isAuthenticated} = useAuthContext()

  const [showDialog, setShowDialog] = useState(false)
  const [selectedMonth, setSelectedMonth] = useGlobalSelectedMonth()
  const [monthDetails, fetchMonthDetails] = useMonthDetails({selectedMonth})

  async function onChangeSelectedMonth(date: Date) {
    setSelectedMonth(date)
    await fetchMonthDetails(date)
  }

  function renderDetails() {
    function onlyPlannedIncomes(intent: Intent): boolean {
      return intentMainColor(intent) === MoneyColor.Income
    }

    function intentToTagTR(intent: Intent, index: number): JSX.Element {
      return (
        <IntentListElement
          key={intent.id}
          onChangedIntent={fetchMonthDetails}
          onDeletedIntent={fetchMonthDetails}
          onPaidMoney={fetchMonthDetails}
          intent={intent}
          index={index}
          transactions={getIntentPaidTransactions(monthDetails, intent)}
          onChangedPaidTransaction={fetchMonthDetails}
          onDeletedPaidTransaction={fetchMonthDetails}
          selectedMonth={selectedMonth}
          pockets={monthDetails.pockets}
        />
      )
    }

    const plannedIncomesTR = monthDetails.intents.filter(onlyPlannedIncomes).map(intentToTagTR)

    return (
      <ThemeProvider theme={geldTheme}>
        <Box style={{maxWidth: 750, margin: 'auto'}}>

          <Stack direction='row' justifyContent="space-between" alignItems="center">
            <IconButton
              color="primary"
              sx={{ml: 2}}
              onClick={() => setShowDialog(true)}
            >
              <AddIcon fontSize="large"/>
            </IconButton>

            <Stack direction='row' alignItems="center" spacing={1}>
              <Typography component="div">
                Дневной бюджет:
              </Typography>
              <Typography variant="h6" component="div">
                {monthDetails.everydayBaseBudget}
              </Typography>
            </Stack>

            <MonthSelector
              style={{float: 'right', padding: '8px'}}
              selectedMonth={selectedMonth}
              onChangeSelectedMonth={onChangeSelectedMonth}
            />
          </Stack>

          <TableContainer component={Paper}>
            <Table
              sx={{minWidth: devicePixelRatio}}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Название</TableCell>
                  <TableCell align="center">Сумма</TableCell>
                  <TableCell align="center">Статус</TableCell>
                  <TableCell align="center">Получено</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {plannedIncomesTR.sort(() => 0)}
              </TableBody>

              {/*<TableHead>*/}
              {/*  <TableRow>*/}
              {/*    <TableCell align="center">ИТОГО</TableCell>*/}
              {/*    <TableCell align="center">*/}
              {/*      {calculateExpensesSum(monthDetails.intents)}*/}
              {/*    </TableCell>*/}
              {/*    <TableCell align="center"/>*/}
              {/*    <TableCell align="center">*/}
              {/*      {sumIntentExpensesTransactions(monthDetails.transactions)}*/}
              {/*    </TableCell>*/}
              {/*  </TableRow>*/}
              {/*</TableHead>*/}
            </Table>
          </TableContainer>
        </Box>

        <IntentDialog
          show={showDialog}
          onClose={() => setShowDialog(false)}
          onAddedIntent={fetchMonthDetails}
          moneyColor={MoneyColor.Income}
          selectedMonth={selectedMonth}
          pockets={monthDetails.pockets}
        />
      </ThemeProvider>
    )
  }

  return (
    <div>
      {isAuthenticated ? renderDetails() : LoginSuggestion()}
    </div>
  );
}
