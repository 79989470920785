import {Stack, TextField} from "@mui/material";
import React from "react";
import {addMonths, changeCurrentTimeInCurrentMonth} from "../logic/date";
import IconButton from "@mui/material/IconButton";
import {ArrowLeft, ArrowRight} from "@mui/icons-material";

export interface Props {
  style?: React.CSSProperties;
  selectedMonth: Date
  onChangeSelectedMonth: (date: Date) => Promise<void>
}

export default function MonthSelector(props: Props): JSX.Element {

  async function handleChangeDate(value: Date) {
    let date = changeCurrentTimeInCurrentMonth(value)
    await props.onChangeSelectedMonth(date)
  }

  async function onPreviousMonth() {
    let date = addMonths(props.selectedMonth, -1)
    await handleChangeDate(date)
  }

  async function onNextMonth() {
    let date = addMonths(props.selectedMonth, 1)
    await handleChangeDate(date)
  }

  return (
    <Stack
      direction="row"
      style={props.style}
    >
      <IconButton onClick={onPreviousMonth}>
        <ArrowLeft/>
      </IconButton>

      <TextField
        type="month"
        variant="standard"
        onChange={event => handleChangeDate(new Date(event.target.value))}
        value={props.selectedMonth.toISOString().substring(0, 7)}
      />

      <IconButton onClick={onNextMonth}>
        <ArrowRight/>
      </IconButton>
    </Stack>
  );
}
