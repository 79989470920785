import {Intent, MoneyColor, Transaction} from "./types";

export function intentMainColor(i: Intent): MoneyColor | undefined {
  if (i.fromPocketID != null && i.toPocketID != null) {
    return undefined
  }

  if (i.color) {
    return i.color
  }

  if (i.fromPocketID != null) {
    return MoneyColor.Income
  }
  if (i.toPocketID != null) {
    return MoneyColor.Expense
  }

  return undefined // Intent has no color and no pocket
}
