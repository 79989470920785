import {createTheme} from "@mui/material/styles";

export const geldTheme = createTheme({
  palette: {
    primary: {
      main: '#6750A4',
    },
    background: {
      paper: '#FFFBFE',
      default: '#7D5260'
    }
  },
});
