import {apiFetchMonthDetails} from "../logic/requests";
import {useEffect, useState} from "react";
import {useAuthContext} from "../logic/AuthContext";
import {MonthDetails, ZeroMonthDetails} from "../logic/types";

export interface Props {
  selectedMonth: Date
}

export default function useMonthDetails(props: Props): [MonthDetails, (date?: Date) => Promise<void>]  {
  const {isAuthenticated} = useAuthContext()

  const [monthDetails, setMonthDetails] = useState<MonthDetails>(ZeroMonthDetails);

  useEffect(() => {
    if (isAuthenticated) {
      fetchMonthDetails()
    }
  }, [isAuthenticated])

  async function fetchMonthDetails(when?: Date) {
    if (!when)
      when = props.selectedMonth
    console.log("fetchMonthDetails")
    const result = await apiFetchMonthDetails(when)
    if (result) {
      // console.log(result)
      setMonthDetails(result)
    }
  }

  return [monthDetails, fetchMonthDetails]
}