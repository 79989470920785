import {Button, TextField} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";

console.log('ExamplePage.tsx')

export default function ExamplePage() {
  console.log('ExamplePage')

  const [open, setOpen] = useState(false);
  console.log(`useState open ${open}`)

  function ExampleDialog() {
    console.log(`ExampleDialog open ${open}`)

    const [amount, setAmount] = useState(1);
    console.log(`ExampleDialog amount ${amount}`)

    function onChangeAmount(event: ChangeEvent<HTMLInputElement>) {
      let newAmount = parseInt(event.target.value);
      if (newAmount === 2) {
        newAmount = 99
      }
      console.log(`ExampleDialog onChangeAmount newAmount ${newAmount}`)
      setAmount(newAmount)
    }

    useEffect(() => {
      console.log(`ExampleDialog useEffect amount ${amount}`)
    }, [amount])

    return (
      <div>
        <h1>Example Dialog</h1>

        <TextField
          label="Переодичность"
          type="number"
          onChange={onChangeAmount}
          value={amount}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            console.log(`Close Dialog`)
            setOpen(false)
          }}
        >
          Close Dialog
        </Button>
      </div>
    )
  }

  return (
    <div>
      <h1>Example Page</h1>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          console.log(`Open Dialog`)
          setOpen(true)
        }}
      >
        Open Dialog
      </Button>

      <div>Dialog State: {open ? 'true' : 'false'}</div>

      {open && <ExampleDialog/>}
    </div>
  )
}

