import React, { useState } from "react";
import { Pocket } from "../logic/types";
import { TableCell, TableRow } from "@mui/material";
import PocketDetailsDialog from "./PocketDetailsDialog";

export type PocketListElementProps = {
  onChangedPocket: () => void
  onDeletedPocket: () => void
  pocket: Pocket
  index: number
  pockets: Array<Pocket>
}

export default function PocketListElement(props: PocketListElementProps) {
  const [showPocketDetailsDialog, setShowPocketDetailsDialog] = useState(false)

  return (
    <>
      <TableRow onClick={() => setShowPocketDetailsDialog(true)}>
        <TableCell align="center">{props.index + 1}</TableCell>
        <TableCell align="center">{props.pocket.title}</TableCell>
        <TableCell align="center">{props.pocket.balance}</TableCell>
      </TableRow>

      <PocketDetailsDialog
        pocket={props.pocket}
        show={showPocketDetailsDialog}
        onClose={() => setShowPocketDetailsDialog(false)}
        onChangedPocket={props.onChangedPocket}
        onDeletedPocket={props.onDeletedPocket}
        pockets={props.pockets}
      />
    </>
  );
}
